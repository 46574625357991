import React, { Component } from "react";
import "./App.css";
import MetaData from "../util/MetaData";
import Person from "../model/Person";

class App extends Component {
  state = {
    tab: 0,
    persons: []
  };

  nextPersonId = 0;
  fileInput = React.createRef();

  render() {
    const { tab } = this.state;
    return (
      <React.Fragment>
        <nav
          className="navbar navbar-light navbar-expand-md flex-column"
          role="navigation"
        >
          <div className="navbar-brand">Seating Planner</div>
        </nav>
        <div className="container col-12 col-lg-10  col-xl-8 mt-2">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                className={"nav-link" + (tab === 0 ? " active" : "")}
                onClick={() => this.setState({ tab: 0 })}
                href="./#"
              >
                Persons
              </a>
            </li>
            <li className="nav-item">
              <a
                className={"nav-link" + (tab === 2 ? " active" : "")}
                onClick={() => this.setState({ tab: 2 })}
                href="./#"
              >
                Import / Export
              </a>
            </li>
          </ul>
          {(tab === 2 && this.renderImportExportTab()) ||
            this.renderPersonsTab()}
        </div>
        <footer className="col-12 small text-center text-black-20 mt-2 pt-4 pb-2">
          Version {MetaData.getVersion()} &copy; {MetaData.getBuildYear()}
          <br />
          Powered by LoPoBo
        </footer>
      </React.Fragment>
    );
  }

  renderPersonsTab() {
    const { persons } = this.state;
    return (
      <table className="table table-hover table-borderless">
        <colgroup>
          <col className="table-primary" />
          <col className="table-success" />
          <col className="table-danger" />
        </colgroup>
        <thead>
          <tr>
            <th style={{ width: "40%" }} scope="col">
              Persons
            </th>
            <th style={{ width: "30%" }} className="table-success" scope="col">
              Positive
            </th>
            <th style={{ width: "30%" }} scope="col">
              Negative
            </th>
          </tr>
        </thead>
        <tbody>
          {persons.map((person, index) => {
            const unrelatedPersons = persons.filter(
              p => p.id !== person.id && !person.hasRelation(p)
            );
            return (
              <tr key={person.id}>
                <td>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      value={person.name}
                      onChange={event => {
                        const value = event.target.value;
                        persons[index].name = value;
                        this.setState({ persons });
                      }}
                      onBlur={event => {
                        const value = event.target.value.trim();
                        if (value) {
                          persons[index].name = value;
                          this.setState({ persons });
                        } else {
                          this.removePerson(index);
                        }
                      }}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn input-group-text text-secondary"
                        onClick={() => this.removePerson(index)}
                      >
                        X
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  {this.renderRelationAdder(unrelatedPersons, otherPerson =>
                    person.addPositiveRelation(otherPerson)
                  )}
                  {this.renderRelationRemover(
                    person.getPositiveRelations(),
                    otherPerson => person.removeRelation(otherPerson)
                  )}
                </td>
                <td>
                  {this.renderRelationAdder(unrelatedPersons, otherPerson =>
                    person.addNegativeRelation(otherPerson)
                  )}
                  {this.renderRelationRemover(
                    person.getNegativeRelations(),
                    otherPerson => person.removeRelation(otherPerson)
                  )}
                </td>
              </tr>
            );
          })}
          <tr>
            <td>
              <input
                type="text"
                className="form-control"
                placeholder="Enter name…"
                onBlur={event => {
                  const value = event.target.value.trim();
                  if (value) {
                    this.addPerson(value);
                  }
                  event.target.value = "";
                }}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    const value = event.target.value.trim();
                    if (value) {
                      this.addPerson(value);
                    }
                    event.target.value = "";
                  }
                }}
              />
            </td>
            <td>{this.renderRelationAdder([], () => {})}</td>
            <td>{this.renderRelationAdder([], () => {})}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderRelationAdder(unrelatedPersons, addAction) {
    const { persons } = this.state;
    return (
      <select
        className="form-control"
        disabled={!unrelatedPersons.length}
        onChange={event => {
          //TODO: Polyfill find
          const otherPerson = persons.find(
            p => p.id.toString() === event.target.value
          );
          if (otherPerson) {
            addAction(otherPerson);
            this.setState({ persons });
          }
        }}
      >
        <option value="">Add…</option>
        {unrelatedPersons.map(otherPerson => (
          <option key={otherPerson.id} value={otherPerson.id}>
            {otherPerson.name}
          </option>
        ))}
      </select>
    );
  }

  renderRelationRemover(relatedPersons, removeAction) {
    const { persons } = this.state;
    return (
      relatedPersons && (
        <div className="mt-1">
          {relatedPersons.map(otherPerson => (
            <div
              className="d-inline-block m-1 py-0 px-2 border border-dark rounded"
              key={otherPerson.id}
            >
              {otherPerson.name}
              <button
                className="btn btn-link text-secondary m-0 ml-1 p-0 btn-sm"
                onClick={() => {
                  removeAction(otherPerson);
                  this.setState({ persons });
                }}
              >
                X
              </button>
            </div>
          ))}
        </div>
      )
    );
  }

  addPerson(name) {
    const { persons } = this.state;
    persons.push(new Person(this.nextPersonId++, name));
    this.setState({ persons });
  }

  removePerson(index) {
    const { persons } = this.state;
    persons[index].clearRelations();
    persons.splice(index, 1);
    this.setState({ persons });
  }

  renderImportExportTab() {
    return (
      <React.Fragment>
        <div className="row mt-2">
          <div className="col">
            <p> Here you can save your work by exporting it to a file.</p>
            <a
              className="btn btn-primary"
              href={
                "data:application/octet-stream;charset=UTF-8," +
                encodeURIComponent(JSON.stringify(this.createExportObject()))
              }
              download="seating.json"
            >
              Export and download
            </a>
          </div>
        </div>
        <hr />
        <div className="row mt-2">
          <div className="col">
            <p>Here you can load the file again to resume your work.</p>
            <div className="custom-file">
              <input
                id="importFile"
                className="custom-file-input"
                type="file"
                ref={this.fileInput}
                onChange={event => {
                  const file = event.target.files[0];
                  const fileReader = new FileReader();
                  fileReader.onload = event =>
                    this.onImportObject(JSON.parse(event.target.result));
                  fileReader.readAsText(file);
                }}
              />
              <label className="custom-file-label" htmlFor="importFile">
                Choose file to import…
              </label>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  createExportObject() {
    const { persons } = this.state;
    return {
      personData: Person.serializeArray(persons)
    };
  }

  onImportObject(object) {
    const persons = Person.deserializeArray(object.personData);
    this.nextPersonId =
      persons.reduce((max, person) => Math.max(max, person.id), 0) + 1;
    this.setState({ tab: 0, persons });
  }
}

export default App;
