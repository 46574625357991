import { version as rawVersion } from "../../package.json";
import { buildYear } from "../build_data.json";

class MetaData {
  static instance = new MetaData();

  version;

  constructor() {
    if (this.isRelease()) {
      this.version = rawVersion;
    } else if (this.isBeta()) {
      this.version = rawVersion + "-beta";
    } else {
      this.version = rawVersion + "-alpha";
    }
  }

  getRawVersion() {
    return rawVersion;
  }

  getVersion() {
    return this.version;
  }

  isRelease() {
    return (
      process.env.NODE_ENV === "production" &&
      process.env.REACT_APP_BETA !== "true"
    );
  }

  isBeta() {
    return (
      process.env.NODE_ENV === "production" &&
      process.env.REACT_APP_BETA === "true"
    );
  }

  getBuildYear() {
    return buildYear;
  }
}

export default MetaData.instance;
