import Relation from "./Relation";

class Person {
  id = 0;
  name = "";
  _relations = new Map();

  constructor(id, name) {
    this.id = id || 0;
    this.name = name || "";
  }

  hasRelation(otherPerson) {
    return this._relations.has(otherPerson.id);
  }

  getPositiveRelations() {
    return this._getRelations(Relation.Type.POSITIVE);
  }

  getNegativeRelations() {
    return this._getRelations(Relation.Type.NEGATIVE);
  }

  _getRelations(type) {
    const result = [];
    this._relations.forEach(relation => {
      if (relation.type === type) {
        result.push(relation.target);
      }
    });
    return result;
  }

  addPositiveRelation(otherPerson) {
    this._addRelation(Relation.Type.POSITIVE, otherPerson);
  }

  addNegativeRelation(otherPerson) {
    this._addRelation(Relation.Type.NEGATIVE, otherPerson);
  }

  _addRelation(type, otherPerson, twoway = true) {
    this._relations.set(otherPerson.id, new Relation(type, otherPerson));
    if (twoway) {
      otherPerson._addRelation(type, this, false);
    }
  }

  removeRelation(otherPerson) {
    this._removeRelation(otherPerson);
  }

  _removeRelation(otherPerson, twoway = true) {
    this._relations.delete(otherPerson.id);
    if (twoway) {
      otherPerson._removeRelation(this, false);
    }
  }

  clearRelations() {
    this._relations.forEach(relation =>
      relation.target.removeRelation(this, false)
    );
    this._relations.clear();
  }

  static serializeArray(personArray) {
    const idMapping = new Map();
    const persons = [];
    let relations = [];
    personArray.forEach(person => {
      if (!idMapping.has(person.id)) {
        const newId = persons.length;
        idMapping.set(person.id, newId);
        persons.push(person.name);
        person._relations.forEach(relation => {
          if (person.id < relation.target.id) {
            relations.push([relation.type, newId, relation.target.id]);
          }
        });
      }
    });
    relations = relations.filter(relation => {
      const newTargetId = idMapping.get(relation[2]);
      if (newTargetId === undefined) {
        return false;
      }
      relation[2] = newTargetId;
      return true;
    });
    return { persons, relations };
  }

  static deserializeArray(serializedData) {
    const persons = serializedData.persons.map(
      (name, id) => new Person(id, name)
    );
    serializedData.relations.forEach(relation =>
      persons[relation[1]]._addRelation(relation[0], persons[relation[2]])
    );
    return persons;
  }
}

export default Person;
