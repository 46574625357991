class Relation {
  static Type = Object.freeze({
    POSITIVE: "+",
    NEGATIVE: "-"
  });

  type = null;
  target = null;

  constructor(type, target) {
    this.type = type || null;
    this.target = target || null;
  }
}

export default Relation;
